<section id="pricing" class="section price-plan-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Pricing Plans</h2>
                    <p class="d-none d-sm-block mt-4">Select from our flexible on-premises pricing plans, each designed to fit different needs. Purchase a license tailored to your requirements and enjoy our powerful software with package-specific limits and features. Choose the plan that best matches your goals and scale as needed.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="price-plan-wrapper" style="display: contents;">
                <div class="single-price-plan">
                    <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                        <h4 class="plan-title text-uppercase">
                            Explorer
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$6500</span>
                            <span class="plan-type text-uppercase">/year</span>
                        </div>
                    </div>
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>1 Product</li>
                        <li><i class="icofont-check"></i>1 Game per Product</li>
                        <li><i class="icofont-check"></i>2 Users</li>
                        <li><i class="icofont-check"></i>1GB Event Feed<span class="plan-type">/day</span></li>
                        <li><i class="icofont-check"></i>2 Rewards</li>
                        <li><i class="icofont-close"></i>Tech Support</li>
                        <li><i class="icofont-close"></i>Installation Support</li>
                    </ul>
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase scroll" href="#contact">Get In Touch</a>
                    </div>
                </div>
                <div class="single-price-plan featured">
                    <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                        <h4 class="plan-title text-uppercase">
                            Scout
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$9500</span>
                            <span class="plan-type text-uppercase">/year</span>
                        </div>
                    </div>
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>1 Product</li>
                        <li><i class="icofont-check"></i>5 Games</li>
                        <li><i class="icofont-check"></i>5 Users</li>
                        <li><i class="icofont-check"></i>10GB Event Feed<span class="plan-type">/day</span></li>
                        <li><i class="icofont-check"></i>10 Rewards</li>
                        <li><i class="icofont-check"></i>Limited Tech Support</li>
                        <li><i class="icofont-close"></i>Installation Support</li>
                    </ul>
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase scroll" href="#contact">Get In Touch</a>
                    </div>
                </div>
                <div class="single-price-plan">
                    <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                        <h4 class="plan-title text-uppercase">
                            Elite
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$17500</span>
                            <span class="plan-type text-uppercase">/year</span>
                        </div>
                    </div>
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>Unlimited Product</li>
                        <li><i class="icofont-check"></i>Unlimited Games</li>
                        <li><i class="icofont-check"></i>Unlimited Users</li>
                        <li><i class="icofont-check"></i>Unlimited Event Feed<span class="plan-type">/day</span></li>
                        <li><i class="icofont-check"></i>Unlimited Rewards</li>
                        <li><i class="icofont-check"></i>24/7 Tech Support</li>
                        <li><i class="icofont-check"></i>Installation Support</li>
                    </ul>
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase scroll" href="#contact">Get In Touch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>