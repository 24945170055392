<section class="section work-area ptb_100">
    <!-- Work Slider Wrapper -->
    <div class="work-wrapper d-none d-md-block">
        <div class="work-slider-wrapper" data-aos="zoom-in">
            <!-- Work Slider -->
            <ul class="work-slider owl-carousel">
                <li class="slide-item">
                    <img src="assets/img/screenshot_1.png" alt="">
                </li>
                <li class="slide-item">
                    <img src="assets/img/screenshot_2.png" alt="">
                </li>
                <li class="slide-item">
                    <img src="assets/img/screenshot_3.png" alt="">
                </li>
                <li class="slide-item">
                    <img src="assets/img/screenshot_4.png" alt="">
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <!-- Work Content -->
        <div class="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item active">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Create</h3>
                        <p>Start by building your game using our easy-to-use game builder.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Configure</h3>
                        <p>Customize your game’s rules, goals, and rewards with simple configuration.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Publish</h3>
                        <p>Publish your game instantly with just one click.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Play</h3>
                        <p>Let players jump in and start enjoying your game right away!</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>