import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name: string;
  email: string;
  subject: string;
  message: string;

  constructor() { }

  ngOnInit(): void {
  }

  sendEmail(e: Event) {

    let data = {
      service_id: 'service_pcq3pc2',
      template_id: 'template_iqbn3lh',
      user_id: 'lfmGbvgZQAJe8nw74',
      template_params: {
        'from_name': this.name,
        'message': this.message,
        'from_email': this.email,
        'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
      }
    };

    $.ajax('https://api.emailjs.com/api/v1.0/email/send', {
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json'
    }).done(function () {
      alert('Your mail is sent!');
    }).fail(function (error) {
      alert('Oops... ' + JSON.stringify(error));
    });
  }

}
