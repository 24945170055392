<footer class="section footer-area footer-bg">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo" src="assets/img/pulsifyme_logo.png" alt="">
                        </a>
                        <p class="text-white-50 mt-2 mb-3">Unlock the power of engagement with PulsifyMe—transform your user experience into an exciting journey, where every interaction fuels motivation, rewards loyalty, and drives unstoppable growth.</p>
                        <!-- Social Icons -->
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50 scroll" href="#home">Home</a></li>
                            <li class="py-2"><a class="text-white-50 scroll" href="#features">Features</a></li>
                            <li class="py-2"><a class="text-white-50 scroll" href="#pricing">Pricing</a></li>
                            <li class="py-2"><a class="text-white-50 scroll" href="#environments">Runtime Environments</a></li>
                            <li class="py-2"><a class="text-white-50" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Environments</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" href="#">Docker</a></li>
                            <li class="py-2"><a class="text-white-50" href="#">Kubernetes</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white-50">&copy; Copyrights {{currentYear}} Continuum Corp Pty Ltd trading as PulsifyMe. All rights reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>