<header class="section header-area">
    <div id="appo-header" class="main-header-area nav-white">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- Logo -->
                <a class="navbar-brand" href="#">
                    <img class="logo" src="assets/img/pulsifyme_logo.png" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- PulsifyMe Menu -->
                <div class="collapse navbar-collapse" id="appo-menu">
                    <!-- Header Items -->
                    <ul class="navbar-nav header-items ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link scroll" href="#home" data-toggle="collapse" data-target="#appo-menu">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#features" data-toggle="collapse" data-target="#appo-menu">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#pricing" data-toggle="collapse" data-target="#appo-menu">Pricing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#environments" data-toggle="collapse" data-target="#appo-menu">Environments</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#contact" data-toggle="collapse" data-target="#appo-menu">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>