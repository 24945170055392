<section id="environments" class="section download-area ptb_100">
    <!-- Shapes Container -->
    <div class="shapes-container d-none d-sm-block">
        <div class="shape-2"></div>
        <div class="shape-3"></div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2>Runtime Environments</h2>
                    <p class="my-3">Experience seamless deployment with our Docker-based runtime environments. Our software is packaged as Docker images, ensuring consistent and reliable performance across any infrastructure. Easily integrate and manage our solution within your existing environment.</p>
                    <p class="d-none d-sm-block my-3">Benefit from the scalability and flexibility Docker provides, simplifying your operations with containerized software that runs smoothly wherever deployed. Enjoy the reliability and efficiency that come with containerized applications.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-linux dsp-tc"></i>
                            <p class="dsp-tc">RUN IT ON
                                <br> <span>Linux</span></p>
                        </a>
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-microsoft dsp-tc"></i>
                            <p class="dsp-tc">RUN IT ON
                                <br> <span>Windows</span></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>