<section id="contact" class="contact-area bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Get In Touch</h2>
                    <p class="mt-4">Ready to learn more or see our software in action? Contact us today to request a demo or get additional information—our team is here to help!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Contact Box -->
                <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form id="contact-form" >
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name" required="required" [(ngModel)]="name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" placeholder="Email" required="required" [(ngModel)]="email">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="required" [(ngModel)]="subject">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <textarea class="form-control" name="message" placeholder="Message" required="required" [(ngModel)]="message"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-bordered mt-3 mt-sm-4" type="button" (click)="sendEmail($event)">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
</section>