import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemeNineComponent } from './themes/theme-nine/theme-nine.component';


const routes: Routes = [
  {path: '', component: ThemeNineComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
