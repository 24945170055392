<section id="benifits" class="section benifits-area ptb_100">
    <div class="container">
        <div class="row">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_1.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Reward & Motivate</h3>
                        <p>Inspire action and loyalty by rewarding users at every step, driving deeper engagement with meaningful incentives.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_2.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Easy to use</h3>
                        <p>Effortlessly integrate gamification into your strategy with an intuitive platform designed for simplicity and efficiency.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_3.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">24/7 Support</h3>
                        <p>Get expert assistance whenever you need it—our dedicated support team is available around the clock.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_4.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Keep players engaged</h3>
                        <p>Fuel continuous engagement by keeping users excited with dynamic rewards and evolving goals.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_5.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Containerized</h3>
                        <p>Deploy with confidence using our fully containerized platform, ensuring flexibility and scalability across any environment.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_6.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Personalize journeys</h3>
                        <p>Create unique experiences tailored to each user, guiding them through personalized journeys that keep them coming back.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>