import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroNineComponent } from './components/hero/hero-nine/hero-nine.component';
import { WorkComponent } from './components/work/work.component';
import { FeaturesComponent } from './components/features/features.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { DownloadComponent } from './components/download/download.component';
import { ContactComponent } from './components/contact/contact.component';
import { ScrollupComponent } from './components/scrollup/scrollup.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FooterComponent } from './components/footer/footer.component';
import { ThemeNineComponent } from './themes/theme-nine/theme-nine.component';
import { HeaderTwoComponent } from './components/header/header-two/header-two.component';
import { PromoThreeComponent } from './components/promo/promo-three/promo-three.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    HeroNineComponent,
    WorkComponent,
    FeaturesComponent,
    PricingComponent,
    NewsletterComponent,
    DownloadComponent,
    ContactComponent,
    ScrollupComponent,
    PreloaderComponent,
    FooterComponent,
    ThemeNineComponent,
    HeaderTwoComponent,
    PromoThreeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
