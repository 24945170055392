<section id="features" class="section features-area ptb_100">
    <div class="shapes-container">
        <div class="shape bg-shape"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="features-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">Features</span>
                        <h2 class="text-capitalize">Powerful Features you Need</h2>
                    </div>
                    <p class="my-3">Unleash the full potential of your gamification strategy with PulsifyMe’s powerful features. Design immersive games where every player action drives dynamic gameplay and unlocks exciting rewards. Configure goals and achievements effortlessly, allowing players to earn rewards as they hit milestones and complete challenges. With real-time feedback and motivation built into every interaction, our platform ensures players stay engaged and driven to achieve their objectives. Empower your game with customizable, event-driven mechanics that turn ordinary tasks into extraordinary rewards.</p>

                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                <!-- Featured Items -->
                <div class="featured-items">
                    <ul>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/game.png" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Games</h3>
                                    <p>Build engaging games with ease</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/goal.png" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Goals</h3>
                                    <p>Create motivating, customizable goals</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/reward.png" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Rewards</h3>
                                    <p>Deliver impactful rewards for achievements</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/kafka_event.png" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Events Based</h3>
                                    <p>Drive gameplay with real-time events</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>