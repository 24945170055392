<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>
        <app-hero-nine></app-hero-nine>
        <app-promo-three></app-promo-three>
        <div class="container">
            <div class="row justify-content-center">
                <!-- Section Heading -->
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Super Quick And Easy To Configure</h2>
                        <p class="mt-4">Create and launch your custom game in minutes with our intuitive visual game builder. No coding needed—just configure, publish, and watch players dive in!</p>
                    </div>
                </div>
            </div>
        </div>
        <app-work></app-work>
        <app-features></app-features>
        <app-pricing></app-pricing>
        <app-newsletter></app-newsletter>
        <app-download></app-download>
        <app-contact></app-contact>
        <app-footer></app-footer>
    </div>
</div>